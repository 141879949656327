import { useDateFormat, useNow } from "@vueuse/core";
import type { WeekDayOpening } from "~/utils/sapcc/stores/types";

const featureTypeCountry = "administrative.country";

// Silver Style
export const silverStyle = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: featureTypeCountry,
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: featureTypeCountry,
    elementType: "geometry.stroke",
    stylers: [
      {
        weight: "0.40",
      },
    ],
  },
  {
    featureType: featureTypeCountry,
    elementType: "labels.text",
    stylers: [
      {
        visibility: "on",
      },
      {
        saturation: "-40",
      },
    ],
  },
  {
    featureType: featureTypeCountry,
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#333366",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.stroke",
    stylers: [
      {
        hue: "#ff0000",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
      {
        weight: "1.25",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#9BEEFF",
      },
    ],
  },
];

const dateToday = useDateFormat(useNow(), "ddd.", { locales: "de-DE" });
export function sortByCurrentDay(array: WeekDayOpening[]): WeekDayOpening[] {
  const currentIndex = array.findIndex((item) => item.weekDay === dateToday.value);
  const beforeCurrentDay = array.slice(currentIndex);
  const afterCurrentDay = array.slice(0, currentIndex);

  return [...beforeCurrentDay, ...afterCurrentDay];
}

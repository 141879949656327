<template>
  <div
    class="location-overlay__inner"
    :class="{
      'location-overlay__inner-show-results': showStoreList,
    }"
  >
    <spar-loader v-if="isLoading" class="location-overlay__spinner" />
    <spar-alert
      v-if="mapResultError"
      :type="SparAlertType.error"
      :is-closable="true"
      class="location-overlay__error"
      data-tosca="location-overlay-no-results-error-msg"
      @close="mapResultError = false"
    >
      {{ $t("location.map.search.no_results") }}</spar-alert
    >

    <div v-if="product" class="location-overlay__product-info">
      <div v-if="productImgSrc" class="location-overlay__product-info__img">
        <spar-adaptive-image
          data-tosca="location-overlay-image"
          object-fit="contain"
          :src="productImgSrc"
          :alt="product.imgAlt"
        ></spar-adaptive-image>
      </div>
      <div v-if="product.name1 || product.name2" class="location-overlay__product-name">
        {{ product.name1 }} {{ product.name2 }}
      </div>
    </div>
    <div class="location-overlay__search">
      <form class="location-overlay__search-form" @submit.prevent="setCoordinatesForLocation()">
        <input
          v-model="location"
          type="search"
          :aria-label="$t('location.map.search.placeholder')"
          :placeholder="$t('location.map.search.placeholder')"
          class="location-overlay__search-field"
        />
        <spar-icon-sprite
          symbol="search"
          class="location-overlay__search-icon"
          @click="setCoordinatesForLocation()"
        />
      </form>
    </div>

    <div class="location-overlay__map-wrapper">
      <div class="location-overlay__map">
        <GoogleMap
          ref="mapRef"
          class="location-overlay__map-container"
          :api-key="googleMapsKey"
          :center="coordinates"
          region="AT"
          :zoom="zoomLevel"
          :street-view-control="false"
          :map-type-control="false"
          :styles="silverStyle"
          @bounds_changed="handleUpdateMap()"
          @dragstart="isDragging = true"
          @dragend="isDragging = false"
          @zoom_changed="updateZoom()"
        >
          <InfoWindow
            v-if="infoWindowStore"
            :options="infoWindowOptions"
            @closeclick="selectStoreMarker(undefined)"
          >
            <div class="location-overlay__store-details">
              <spar-location-overlay-availability :store="infoWindowStore" />

              <spar-heading
                additional-class="heading--left"
                level="4"
                headline-style="4"
                :title="infoWindowStore.displayName"
              />
              <div class="location-overlay__store-details-info">
                {{ infoWindowStore.address.line1 }}, {{ infoWindowStore.address.postalCode }}
                {{ infoWindowStore.address.town }}
              </div>
              {{ $t("location.map.stores.phone") }}:
              <spar-link
                class="location-overlay__store-details-phone"
                :link="`tel:${infoWindowStore.address.phone}`"
                :aria-label="`${$t('location.map.stores.phone')}: ${infoWindowStore.address.phone}`"
                :label="infoWindowStore.address.phone"
              />

              <div
                class="location-overlay__store-open-info"
                :class="{
                  'location-overlay__store--open-now': infoWindowStore.currentOpening.willCloseAt,
                }"
              >
                {{
                  infoWindowStore.currentOpening.willCloseAt
                    ? $t("location.openNow")
                    : $t("location.closed")
                }}
              </div>

              <spar-location-actions
                :is-favourite="favouriteStore?.storeId !== infoWindowStore.storeId"
                :store="infoWindowStore"
                :show-detail-link="false"
                @set-favourite-store-handler="setFavouriteStoreHandler"
              />
            </div>
          </InfoWindow>
        </GoogleMap>
      </div>
    </div>
    <div v-if="showStoreList && !isLoading" class="location-overlay__list">
      <spar-heading
        class="location-overlay__store-list-header"
        level="3"
        headline-style="3"
        :title="`${stores?.length || '0'} ${$t('location.map.header')}`"
      />
      <div v-if="stores?.length" class="location-overlay__store-list">
        <div
          v-for="(store, storeIndex) in stores"
          :id="'store' + store.openingHours.code"
          :key="store.name"
        >
          <div
            v-if="storeIndex < maximumStoreList"
            class="location-overlay__store-item"
            :class="{
              'location-overlay__store-item--selected': selectedStoreId === store.openingHours.code,
            }"
          >
            <spar-location-overlay-availability :store="store" />

            <spar-heading
              level="3"
              headline-style="4"
              class="location-overlay__store-title"
              :title="store.displayName"
              additional-class="heading--left"
            />

            <div v-if="store.openingHours" class="location-overlay__store-info">
              <spar-opening-hours
                :opening-hours="store.openingHours"
                :current-opening="store.currentOpening"
                :title="$t('location.map.opening_hours')"
                :show-more-label="$t('location.map.show.opening_hours')"
              />
            </div>

            <div v-if="store.address" class="location-overlay__store-info">
              <spar-heading
                class="location-overlay__store-headline"
                level="4"
                headline-style="4"
                :title="$t('location.map.address')"
                additional-class="heading--left"
              />
              {{ store.address.line1 }}, {{ store.address.postalCode }} {{ store.address.town }}
              <div class="location-overlay__store-phone">
                {{ $t("location.map.stores.phone") }}:

                <spar-link
                  class="location-overlay__store-details-phone"
                  :link="`tel:${store.address.phone}`"
                  :aria-label="`${$t('location.map.stores.phone')}: ${store.address.phone}`"
                  :label="store.address.phone"
                />
              </div>
            </div>

            <spar-location-actions
              :is-favourite="favouriteStore?.storeId !== store.openingHours.code"
              :store="store"
              @set-favourite-store-handler="setFavouriteStoreHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import * as pkg from "@googlemaps/markerclusterer";
import { InfoWindow, GoogleMap } from "vue3-google-map";
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import { SparAlertType } from "~/components/shared/SparAlert/SparAlert.types";
import SparAlert from "~/components/shared/SparAlert/SparAlert.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import SparLoader from "~/components/shared/SparLoader/SparLoader.vue";
import SparOpeningHours from "~/components/shared/SparOpeningHours/SparOpeningHours.vue";
import { useFavouriteStore } from "~/composables/user/useFavouriteStore";
import mapAvailableImage from "~/public/images/map-available.png";
import mapDefaultPin from "~/public/images/map-circle-filled-1.png";
import mapNotAvailableImage from "~/public/images/map-not-available.png";
import { useLocationStore } from "~/stores/location.store";
import type { SapStore } from "~/utils/sapcc/stores/types";
import SparLocationActions from "./SparLocationActions/SparLocationActions.vue";
import type {
  Coordinates,
  GeocoderError,
  GoogleMapsRef,
  LocationOverlayProduct,
} from "./SparLocationOverlay.types";
import { silverStyle } from "./SparLocationOverlay.utils";
import SparLocationOverlayAvailability from "./SparLocationOverlayAvailability/SparLocationOverlayAvailability.vue";

const props = defineProps({
  product: {
    type: Object as PropType<LocationOverlayProduct>,
    required: true,
  },
});

// Salzburg
const coordinates = ref<Coordinates>({
  lat: 47.80949,
  lng: 13.05501,
});
const zoomLevel = ref(12);
const runtimeConfig = useRuntimeConfig();
const { $t } = useI18n();
const { favouriteStore, setFavouriteStore } = useFavouriteStore();
const { loadStores, loadOutletsAvailability } = useLocationStore();
const { stores } = storeToRefs(useLocationStore());
const { googleMapsKey } = runtimeConfig.public;

const mapRef = ref<GoogleMapsRef | null>(null);
const location = ref("");
const selectedStoreId = ref<string>();
const infoWindowStoreId = ref<string>();
const isLoading = ref(true);
const isDragging = ref(false);
const mapUpdating = ref(false);
const mapResultError = ref(false);
const isOutletsLoaded = ref(false);
const bounds = ref<google.maps.LatLngBoundsLiteral>();

let clusterer: pkg.MarkerClusterer | null = null;
const currentZoom = ref<number | undefined>(15);
const showStoreList = ref(false);
// show storeList and pinInfos at this zoom and higher
const storeListZoomBorder = 12;
const maximumStoreList = 50;

// center map on favouriteStore if is set
watch(
  () => mapRef.value?.ready,
  async (ready) => {
    if (ready) {
      centerFavouriteStore();
    }
  },
  { deep: true },
);
watch(
  () => isDragging.value,
  async (dragging) => {
    // ensure map-update afert dragging has finished
    if (!dragging && !mapUpdating.value) {
      updateMap();
    }
  },
  { deep: true },
);

onMounted(async () => {
  if (favouriteStore.value?.storeId) {
    coordinates.value.lat = favouriteStore.value.lat;
    coordinates.value.lng = favouriteStore.value.lng;
  }

  await loadOutletsAvailability(props.product.productId);
  isOutletsLoaded.value = true;
});

const updateZoom = () => {
  currentZoom.value = mapRef.value?.map.getZoom();
};

/**
 * Wait for finished loadOutletsAvailability call before loading
 * the markers on the map
 */
const handleUpdateMap = () => {
  if (isOutletsLoaded.value) {
    return updateMap();
  }

  watch(
    isOutletsLoaded,
    () => {
      updateMap();
    },
    { once: true },
  );
};

const updateMap = async () => {
  if (!mapRef.value?.ready || isDragging.value) return;

  bounds.value = mapRef.value.map.getBounds()?.toJSON();
  const center = mapRef.value.map.getCenter()?.toJSON();
  if (!bounds.value || !center) return;

  if (mapUpdating.value) return;
  mapUpdating.value = true;

  coordinates.value.lat = center.lat;
  coordinates.value.lng = center.lng;

  await load();

  mapUpdating.value = false;
};

const setFavouriteStoreHandler = (store: SapStore | undefined) => {
  if (!store?.geoPoint.latitude || !store?.geoPoint.longitude) return;

  setFavouriteStore({
    storeId: store?.storeId,
    lat: store?.geoPoint.latitude,
    lng: store?.geoPoint.longitude,
  });
  centerFavouriteStore();
};

const centerFavouriteStore = async () => {
  if (!favouriteStore.value) return;

  if (!mapRef.value?.ready) return;

  zoomLevel.value = 13;

  if (mapRef.value?.ready && favouriteStore.value.lat && favouriteStore.value.lng) {
    mapRef.value.map.panTo({ lat: favouriteStore.value.lat, lng: favouriteStore.value.lng });
  }

  selectedStoreId.value = favouriteStore.value.storeId;
};

const load = async () => {
  isLoading.value = true;

  // set current zoom
  updateZoom();

  let pinSize = 40;

  if (currentZoom.value && currentZoom.value >= storeListZoomBorder) {
    // load stores to render storelist
    showStoreList.value = true;
    await loadStores(coordinates.value, bounds.value);
  } else {
    showStoreList.value = false;
    pinSize = 48;

    // dont load full details to prevent large responses
    await loadStores(
      coordinates.value,
      bounds.value,
      "stores(displayName,geoPoint,openingHours(code))",
    );
  }

  isLoading.value = false;

  // TODO update to AdvancedMarker when published to avoid warnings - only deprecated no security
  // https://github.com/inocan-group/vue3-google-map/pull/263
  const markersMapped = stores.value?.map((store) => {
    const marker = new google.maps.Marker({
      position: { lat: store.geoPoint.latitude, lng: store.geoPoint.longitude },
      icon: {
        url: getPinImg(store.bestand),
        scaledSize: new google.maps.Size(pinSize, pinSize),
      },
      zIndex: store.bestand ? 1 : 0, // always show available icons on top
      clickable: true,
    });

    marker.addListener("click", () => {
      selectStoreMarker(store.openingHours.code);
    });

    return marker;
  });

  if (clusterer) {
    clusterer.clearMarkers();
  }

  const { SuperClusterAlgorithm, MarkerClusterer } = pkg;
  // set markerCluster to markers
  // we dont use vue3-google-map markercluster because of bad performance
  clusterer = new MarkerClusterer({
    map: mapRef.value?.map,
    markers: markersMapped,
    algorithm: new SuperClusterAlgorithm({
      radius: 60,
      maxZoom: storeListZoomBorder,
    }),
  });

  if (favouriteStore.value && favouriteStore.value.storeId) {
    scrollIntoView(favouriteStore.value.storeId);
  }
};

const setCoordinatesForLocation = async () => {
  if (!mapRef.value?.ready) return;

  const mapApi = mapRef.value.api;
  if (!mapApi) return;

  const geocoder = new mapApi.Geocoder();
  mapResultError.value = false;
  try {
    const { results } = await geocoder.geocode({
      address: location.value,
      language: "de",
      componentRestrictions: { country: "AT" },
    });

    const center = results[0]?.geometry.location.toJSON();
    if (!center) return;

    coordinates.value.lat = center.lat;
    coordinates.value.lng = center.lng;
    mapRef.value.map.setCenter(center);
    mapRef.value.map.fitBounds(results[0].geometry.viewport);

    mapRef.value.map.panTo({ lat: center.lat, lng: center.lng });
  } catch (error: unknown) {
    if (isGeocoderError(error) && error.code === "ZERO_RESULTS") {
      mapResultError.value = true;
    }
  }
};

function isGeocoderError(error: unknown): error is GeocoderError {
  return typeof error === "object" && error !== null && "code" in error;
}

const selectStoreMarker = (storeId: string | undefined) => {
  infoWindowStoreId.value = storeId;
};

const infoWindowStore = computed(() => {
  if (!infoWindowStoreId.value) return undefined;
  return stores.value?.find((store) => store.storeId === infoWindowStoreId.value);
});

const scrollIntoView = (code: string) => {
  if (!code) return;
  const parent = document.querySelector(".location-overlay__store-list");
  const elem = document.getElementById("store" + code);
  if (elem) {
    parent?.scrollTo({
      top: elem.getBoundingClientRect().top - parent?.getBoundingClientRect().top,
      left: 0,
    });
  }
};

const getPinImg = (isInStock: boolean | undefined) => {
  if (currentZoom.value && currentZoom.value >= storeListZoomBorder) {
    return isInStock ? mapAvailableImage : mapNotAvailableImage;
  }
  return mapDefaultPin;
};

const infoWindowOptions = computed(() => {
  if (!infoWindowStore.value) return;

  return {
    pixelOffset: new google.maps.Size(-1, -30),
    position: {
      lat: infoWindowStore.value.geoPoint.latitude,
      lng: infoWindowStore.value.geoPoint.longitude,
    },
  };
});

const productImgSrc = computed(() =>
  props.product.imgSrc.replace("{size}", "50").replace("{ext}", "jpg"),
);
</script>

<style lang="scss">
@use "./SparLocationOverlay.scss";
</style>
